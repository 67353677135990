$primaryText: #212121;
$secondaryText: #6c7281;

$grey: #d7dbe7;
$red: #d70f64;
$pink: #e9adc7;
$green: #00c853;

$desktop-minimum-container-width: 720px;

$glowing_green_light: #5efc82;
$glowing_green: $green;
$glowing_red_light: #ff5131;
$glowing_red: #d50000;
$glowing_yellow_light: #ffff52;
$glowing_yellow: #ffd600;
@keyframes glowingRED {
  0% {
    box-shadow: 0 0 -10px $glowing_red;
  }
  40% {
    box-shadow: 0 0 20px $glowing_red;
  }
  60% {
    box-shadow: 0 0 20px $glowing_red;
  }
  100% {
    box-shadow: 0 0 -10px $glowing_red;
  }
}
@keyframes glowingGREEN {
  0% {
    box-shadow: 0 0 -10px $glowing_green;
  }
  40% {
    box-shadow: 0 0 20px $glowing_green;
  }
  60% {
    box-shadow: 0 0 20px $glowing_green;
  }
  100% {
    box-shadow: 0 0 -10px $glowing_green;
  }
}
@keyframes glowingYELLOW {
  0% {
    box-shadow: 0 0 -10px $glowing_yellow;
  }
  40% {
    box-shadow: 0 0 20px $glowing_yellow;
  }
  60% {
    box-shadow: 0 0 20px $glowing_yellow;
  }
  100% {
    box-shadow: 0 0 -10px $glowing_yellow;
  }
}
