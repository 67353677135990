@import 'src/common.scss';

html {
  -webkit-font-smoothing: antialiased;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;

  color: $primaryText;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: $primaryText;

  font-weight: 300;
  line-height: 1.6;

  @media (min-width: $desktop-minimum-container-width) {
    width: 720px;
    margin: 0 auto;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
  color: $primaryText;
}

.logo-small {
  transform: scale(0.8);
}
.logo-foodora {
  background: url('/assets/foodora.png');

  width: 150px;
  height: 27px;
}
.logo-talabat {
  background: url('/assets/talabat.svg');

  width: 150px;
  height: 31px;
}
.logo-foodpanda {
  background: url('/assets/pandago.pink.svg');

  width: 120px;
  height: 30px;
}
.logo-otlob {
  background: url('/assets/otlob-long.svg');

  width: 134.2px;
  height: 46.4px;
}
.logo-otlob-alt {
  background: url('/assets/otlob-round.svg');

  width: 50px;
  height: 50px;

  margin-bottom: 16px;
}
.logo {
  color: rgb(33, 33, 33);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.31px;

  text-align: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.react-tel-input .form-control + div:before {
  content: '' !important;
}

.unselectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
